import CardProducer from '~/components/Molecules/Organic/CardProducer/CardProducer.vue'
import modalSignup from '~/mixins/modalSignup.js'

export default {
  mixins: [modalSignup],

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    producersList: {
      type: Array,
      default: [],
    },

    cta: {
      type: Object,
    },
  },

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },

  data() {
    return {
      cardProducer: CardProducer,
      swiperOptions: {
        spaceBetween: 24,
        speed: 7000,
        allowTouchMove: false,
        centeredSlides: true,
        loop: true,
        loopedSlides: 4,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 2.5,
          },
          375: {
            slidesPerView: 1.5,
          },
          320: {
            slidesPerView: 1.2,
          },
        },
      },
    }
  },

  computed: {
    hasCta() {
      return Boolean(this.cta)
    },
  },
}
